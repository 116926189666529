import React, { useState } from "react";
import AudioPlayer from "./AudioPlayer";

//Regex
//\./svg/glyphicons-humans-\d{2,3}-

// ReactComponents
import { ReactComponent as CarryingTwo } from "./svg/glyphicons-humans-47-carrying-two.svg";
// import { ReactComponent as CarryingOnBack } from './svg/glyphicons-humans-48-carrying-on-back.svg';//E
import { ReactComponent as PartnersHandshake } from "./svg/glyphicons-humans-73-partners-handshake.svg";
import { ReactComponent as PartnersHandshakeFormal } from "./svg/glyphicons-humans-74-partners-handshake-formal.svg";
import { ReactComponent as PartnersHandsClap } from "./svg/glyphicons-humans-75-partners-hands-clap.svg";
import { ReactComponent as ChildrenPlaying } from "./svg/glyphicons-humans-76-children-playing.svg";
import { ReactComponent as Queue } from "./svg/glyphicons-humans-81-queue.svg";
import { ReactComponent as HelpingElderly } from "./svg/glyphicons-humans-106-helping-elderly.svg";
import { ReactComponent as GroupMen } from "./svg/glyphicons-humans-111-group-men.svg";
import { ReactComponent as GroupWomen } from "./svg/glyphicons-humans-112-group-women.svg";
import { ReactComponent as GroupWomenMan } from "./svg/glyphicons-humans-113-group-women-man.svg";
import { ReactComponent as GroupMenWoman } from "./svg/glyphicons-humans-114-group-men-woman.svg";
import { ReactComponent as PartnersWaving } from "./svg/glyphicons-humans-115-partners-waving.svg";
import { ReactComponent as PartnersWavingMixed } from "./svg/glyphicons-humans-116-partners-waving-mixed.svg";

import carryingTwoAudio from "./assets/carrying-two.mp3";
import partnersHandshakeAudio from "./assets/partners-handshake.mp3";
import partnersHandshakeFormalAudio from "./assets/partners-handshake-formal.mp3";
import partnersHandsClapAudio from "./assets/partners-hands-clap.mp3";
import childrenPlayingAudio from "./assets/children-playing.mp3";
import queueAudio from "./assets/queue.mp3";
import helpingElderlyAudio from "./assets/helping-elderly.mp3";
import groupMenAudio from "./assets/group-men.mp3";
import groupWomenAudio from "./assets/group-women.mp3";
import groupWomenManAudio from "./assets/group-women-man.mp3";
import groupMenWomanAudio from "./assets/group-men-woman.mp3";
import partnersWavingAudio from "./assets/partners-waving.mp3";
import partnersWavingAudioMixed from "./assets/partners-waving-mixed.mp3";
// import from "./assets/6h.mp3";

export default function App() {
  let questions = [];
  for (let i = 0; i < 15; i++) {
    questions.push(createQuestion(i));
  }

  function getAudio(param) {
    switch (param) {
      case "carrying-two":
        return carryingTwoAudio;
      case "partners-handshake":
        return partnersHandshakeAudio;
      case "partners-handshake-formal":
        return partnersHandshakeFormalAudio;
      case "partners-hands-clap":
        return partnersHandsClapAudio;
      case "children-playing":
        return childrenPlayingAudio;
      case "queue":
        return queueAudio;
      case "helping-elderly":
        return helpingElderlyAudio;
      case "group-men":
        return groupMenAudio;
      case "group-women":
        return groupWomenAudio;
      case "group-women-man":
        return groupWomenManAudio;
      case "group-men-woman":
        return groupMenWomanAudio;
      case "partners-waving":
        return partnersWavingAudio;
      case "partners-waving-mixed":
        return partnersWavingAudioMixed;
      default:
        return partnersWavingAudioMixed;
    }
  }

  function getSVG(param) {
    switch (param) {
      case "carrying-two":
        return <CarryingTwo fill="white" height={100} width={100} />;
      case "partners-handshake":
        return <PartnersHandshake fill="white" height={100} width={100} />;
      case "partners-handshake-formal":
        return (
          <PartnersHandshakeFormal fill="white" height={100} width={100} />
        );
      case "partners-hands-clap":
        return <PartnersHandsClap fill="white" height={100} width={100} />;
      case "children-playing":
        return <ChildrenPlaying fill="white" height={100} width={100} />;
      case "queue":
        return <Queue fill="white" height={100} width={100} />;
      case "helping-elderly":
        return <HelpingElderly fill="white" height={100} width={100} />;
      case "group-men":
        return <GroupMen fill="white" height={100} width={100} />;
      case "group-women":
        return <GroupWomen fill="white" height={100} width={100} />;
      case "group-women-man":
        return <GroupWomenMan fill="white" height={100} width={100} />;
      case "group-men-woman":
        return <GroupMenWoman fill="white" height={100} width={100} />;
      case "partners-waving":
        return <PartnersWaving fill="white" height={100} width={100} />;
      case "partners-waving-mixed":
        return <PartnersWavingMixed fill="white" height={100} width={100} />;
      default:
        return <PartnersWavingMixed fill="white" height={100} width={100} />;
    }
  }

  function createQuestion(i) {
    var array = [
      "carrying-two",
      "partners-handshake",
      "partners-handshake-formal",
      "partners-hands-clap",
      "children-playing",
      "queue",
      "helping-elderly",
      "group-men",
      "group-women",
      "group-women-man",
      "group-men-woman",
      "partners-waving",
      "partners-waving-mixed",
    ];
    // var array = [
    //   <CarryingTwo fill="white" height={100} width={100} />,
    //   <CarryingOnBack fill="white" height={100} width={100} />,
    //   <PartnersHandshake fill="white" height={100} width={100} />,
    //   <PartnersHandshakeFormal fill="white" height={100} width={100} />,
    //   <PartnersHandsClap fill="white" height={100} width={100} />,
    //   <ChildrenPlaying fill="white" height={100} width={100} />,
    //   <Queue fill="white" height={100} width={100} />,
    //   <HelpingElderly fill="white" height={100} width={100} />,
    //   <GroupMen fill="white" height={100} width={100} />,
    //   <GroupWomen fill="white" height={100} width={100} />,
    //   <GroupWomenMan fill="white" height={100} width={100} />,
    //   <GroupMenWoman fill="white" height={100} width={100} />,
    //   <PartnersWaving fill="white" height={100} width={100} />,
    //   <PartnersWavingMixed fill="white" height={100} width={100} />,
    // ];

    array = arrayShuffle(array);
    let end;
    if (i <= 4) end = 2;
    else if (i <= 9) end = 3;
    else end = 4;

    // Question
    const q = array.slice(0, end);
    //Random 5 Items for creating the answers
    const ansSlice = array.slice(0, end + 2);
    let pos = Math.floor(Math.random() * ansSlice.length);
    const selectedFigure = ansSlice[pos];

    const answerIsContained = q.some((element) => {
      if (element === selectedFigure) return true;
      return false;
    });

    console.log(
      "ansSlice: ",
      ansSlice,
      "selectedFigure: ",
      selectedFigure,
      "pos: ",
      pos,
      "answerIsContained: ",
      answerIsContained
    );

    let ao = [];
    ao.push({
      answerText: "Όχι",
      isCorrect: !answerIsContained,
    });
    ao.push({
      answerText: "Ναι",
      isCorrect: answerIsContained,
    });

    return {
      questionText: q.map((question) => getSVG(question)),
      answerOptions: ao,
      audio: selectedFigure,
    };
  }

  function arrayShuffle(array) {
    for (let index = array.length - 1; index > 0; index--) {
      const newIndex = Math.floor(Math.random() * (index + 1));
      [array[index], array[newIndex]] = [array[newIndex], array[index]];
    }
    return array;
  }

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleAnswerOptionClick = (isCorrect) => {
    document.getElementById("question-text").style.display = "flex";
    document.getElementById("answer-section").style.display = "none";

    setVisible(false);

    if (currentQuestion < questions.length - 1) {
      setTimeout(function () {
        document.getElementById("question-text").style.display = "none";
        document.getElementById("answer-section").style.display = "flex";
        setVisible(true);
      }, 2000);
    }

    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  if (currentQuestion === 0) {
    setTimeout(function () {
      document.getElementById("question-text").style.display = "none";
      document.getElementById("answer-section").style.display = "flex";
      setVisible(true);
    }, 2000);
  }

  return (
    <div className="app">
      {showScore ? (
        <div className="score-section">
          Απαντήσατε σωστά στις {score} από τις {currentQuestion + 1} ερωτήσεις
        </div>
      ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Ερώτηση {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div id="question-text">
              {questions[currentQuestion].questionText}
            </div>
          </div>
          <div id="answer-section" style={{ display: "none" }}>
            {questions[currentQuestion].answerOptions.map((answerOption) => (
              <button
                onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}
                color="red"
              >
                {answerOption.answerText}
              </button>
            ))}
            {visible && (
              <AudioPlayer
                key={currentQuestion}
                audio={getAudio(questions[currentQuestion].audio)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
