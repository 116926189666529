import React, { createRef } from "react";
class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      audio: props.audio,
    };
    this.audioRef = createRef();
  }
  dismiss() {
    this.props.unmountMe();
  }
  componentDidMount() {
    console.log("componentDidMount");
    try {
      this.audioRef.current
        .play()
        .then(() => console.log("run perfectly"))
        .catch((error) => console.log("play promisse fails"));
    } catch (error) {
      console.log("try-catch fails");
    }
  }

  handlePlay = () => {
    // setTimeout(this.audioRef.current.play(), 2000);
    this.audioRef.current.play();
    this.audioRef.current.muted = false;
  };

  render() {
    return (
      <div className="App" onTouchStart={this.handlePlay}>
        <audio ref={this.audioRef} id="audioRef" src={this.state.audio} />
      </div>
    );
  }
}

export default AudioPlayer;
